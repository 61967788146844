import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { seoKeyWords } from "../helpers/seo"

class Contact extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Contact Us" keywords={seoKeyWords} />
        <div className="Contact-us">
          <div className="container">
            <h3>
              Have a question about your order or a recommendation to make
              Script Kitty better?
            </h3>
            <h3>Drop us a message!</h3>
            <form
              method="POST"
              name="contact"
              data-netlify="true"
              action="/contact-us"
            >
              <div>
                <label htmlFor="name">Your Name: </label>
                <input type="text" name="name" />
              </div>
              <div>
                <label htmlFor="email">Your Email: </label>
                <input type="email" name="email" />
              </div>
              <div>
                <label htmlFor="message">Message: </label>
                <textarea name="message"></textarea>
              </div>
              <div>
                <button type="submit">Send</button>
              </div>
              <input type="hidden" name="form-name" value="contact" />
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact
